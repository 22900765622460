import React from 'react';
import { Container } from 'react-bootstrap';

const Contact = () => {
  return (
    <Container className="contact-page">
      <h1 className="text-center my-4">Contact Us</h1>
      <p className="text-center">
        If you would like us to link to you, please email us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a>
      </p>
      <address className="text-center">
        Muscletease<br />
        Summit House, 170 Finchley Road<br />
        London, NW3 6BP<br />
        United Kingdom<br />
        <br />
        For membership questions: <a href="mailto:support@ccbill.com">support@ccbill.com</a>
      </address>
      <p>
        We are committed to maintaining high standards on our platform and encourage users to report content that may be illegal or violate our standards.
      </p>
      <h3>Reporting Content:</h3>
      <p>
        To report content, please email us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a> or use the "Report Content" feature on our website. Include the URL and a brief description of the issue.
      </p>
      <h3>Review Process:</h3>
      <ul>
        <li>We will acknowledge your report within 48 hours.</li>
        <li>Our team will review the content and take appropriate action.</li>
        <li>We aim to resolve complaints and inform you of the outcome within seven business days.</li>
      </ul>
      <h3>Appeals Process:</h3>
      <p>
        If your content has been removed, you can appeal the decision by contacting us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a>. We will provide a final decision within 14 business days.
      </p>
      <h3>Outcomes:</h3>
      <p>
        Outcomes may include removal of content, suspension of accounts, or no action if the content complies with our standards.
      </p>
      <h3>Confidentiality:</h3>
      <p>
        All reports and investigations are treated confidentially. For more information, contact us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a>.
      </p>
    </Container>
  );
};

export default Contact;
