import React, { useState } from 'react';
import { Navbar, Nav, Container, Form, FormControl, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';

const Navigation = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search/${searchTerm}`);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Muscletease.com</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/privatesales">
              <Nav.Link>Private Sales</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/vod">
              <Nav.Link>VOD</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/photos">
              <Nav.Link>Photos</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/videos">
              <Nav.Link>Videos</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/join">
              <Nav.Link>Join</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/models">
              <Nav.Link>Models</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/links">
              <Nav.Link>Links</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link>Contact</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/forum">
              <Nav.Link>Forum</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/free">
              <Nav.Link>Free</Nav.Link>
            </LinkContainer>
          </Nav>
          <Form className="d-flex" onSubmit={handleSearch}>
            <FormControl
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button variant="outline-success" type="submit">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
